<!--
### Tabbed button style radio group input
 -->
<script lang="ts">
  import type { ComponentProps } from "svelte";
  import Field from "../FormField/FormField.svelte";
  import FormField from "../FormField/FormField.svelte";

  type Option = { value: Maybe<string>; label?: string; icon?: string };

  type RadioGroupProps = {
    /** Name of group */
    name: string;
    /** Options to choose from */
    options: Option[];
    /** The active option */
    active: Option["value"];
    /** Optional compact theme */
    compact?: boolean;
    /** Whether it should be fullwidth */
    fullwidth?: boolean;
  } & ComponentProps<FormField>;

  let {
    name,
    label = "",
    options,
    active = $bindable(),
    tip,
    compact = false,
    fullwidth = false,
    class: className,
  }: RadioGroupProps = $props();
</script>

<style>
  .options {
    display: inline-flex;
    border: 1px solid var(--color-border);
    border-radius: var(--radius-md);
    overflow: hidden;
    &.fullwidth {
      width: 100%;
    }
  }

  .option {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 var(--size-3);
    height: var(--size-10);
    cursor: pointer;
    transition: background 150ms ease;
    &:not(:last-child) {
      border-right: 1px solid var(--color-border-secondary);
    }
    &.active,
    &:active {
      background: var(--radio-active, var(--color-bg-secondary));
    }
    .compact & {
      padding: 0 var(--size-2);
      height: var(--size-7);
    }
  }

  .option-icon {
    color: var(--color-text-secondary);
    transition: color 150ms ease;
    flex-shrink: 0;
    & :global(svg) {
      width: var(--size-5);
      height: var(--size-5);
    }
    .compact & :global(svg) {
      width: var(--size-4);
      height: var(--size-4);
    }
    .withLabel & {
      margin-right: var(--size-2);
    }
  }
  .active .option-icon {
    color: var(--color-text-primary);
  }
</style>

<Field {label} class={className} {tip}>
  <div class="options" class:fullwidth class:compact>
    {#each options as { label, value, icon }}
      <label
        class="option --text-ui"
        class:icon
        class:withIcon={!!icon}
        class:withLabel={!!label}
        class:active={value === active}
      >
        {#if icon}
          <div class="option-icon">
            {@html icon}
          </div>
        {/if}
        {#if label}
          <span>{label}</span>
        {/if}
        <input hidden type="radio" {name} bind:group={active} {value} />
      </label>
    {/each}
  </div>
</Field>
