<!--
### Select form input
 -->
<script lang="ts">
  import chevronIcon from "lucide-static/icons/chevron-down.svg?raw";
  import Select from "svelte-select";
  import SelectItem from "./SelectItem/SelectItem.svelte";
  import type { ComponentProps } from "svelte";

  type Item = {
    label?: string;
    icon?: any;
    value?: string;
  };

  type SelectProps = {
    /** Array of items to be displayed in the select */
    items: Item[];
    /** Currently selected item */
    value?: Item;
    /** Whether the select has minimal styling */
    minimal?: boolean;
    /** Whether the select is compact */
    compact?: boolean;
    /** Optional change handler */
    onchange?(value: string): void;
  } & ComponentProps<Select>;

  let {
    items,
    value = $bindable(),
    clearable = false,
    searchable = false,
    minimal = false,
    compact = false,
    onchange,
    ...props
  }: SelectProps = $props();
</script>

<style>
  .chevron {
    display: inline-flex;
    align-items: center;
    & :global(svg) {
      width: var(--size-4);
      height: var(--size-4);
      stroke: var(--color-text-secondary);
    }
  }
</style>

<Select
  showChevron
  listOffset={8}
  --height="var(--size-11)"
  --font-size="var(--scale-00)"
  --background="var(--color-bg)"
  --internal-padding="var(--size-2)"
  --border={minimal ? "none" : "1px solid var(--color-border-secondary)"}
  --border-focused={minimal ? "none" : "1px solid var(--color-border)"}
  --border-hover={minimal ? "none" : "1px solid var(--color-border-secondary)"}
  --border-radius="var(--radius-sm)"
  --chevron-height="var(--size-4)"
  --chevron-width="var(--size-4)"
  --chevron-padding="0px"
  --padding="var(--size-2) var(--size-4)"
  --item-padding="var(--size-1) var(--size-4)"
  --input-color="var(--color-text)"
  --item-height="auto"
  --item-is-active-bg="var(--color-bg-tertiary)"
  --item-hover-bg="var(--color-bg-tertiary)"
  --list-background="var(--color-bg)"
  --list-border="1px solid var(--color-border-secondary)"
  --list-border-radius="var(--radius-sm)"
  --list-shadow="none"
  --placeholder-color="var(--color-text-secondary)"
  --placeholder-opacity="1"
  --input-font-size="var(--scale-0)"
  --selected-item-padding={compact
    ? "0 var(--size-1) 0 0"
    : "0 var(--size-2) 0 0"}
  bind:value
  on:change={(e) => {
    onchange?.(e.detail.value);
  }}
  {clearable}
  {searchable}
  {items}
  {...props}
>
  <svelte:fragment slot="item" let:item>
    <SelectItem {item} active={item.value === value?.value} />
  </svelte:fragment>
  <svelte:fragment slot="selection" let:selection>
    <SelectItem item={selection} {compact} />
  </svelte:fragment>
  <span class="chevron" slot="chevron-icon">
    {@html chevronIcon}
  </span>
</Select>
